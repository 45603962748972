<template>
  <div class="team container">
    <h1 class="title is-1 team-title">{{ $t('landing.team.our_team') }}</h1>

    <section class="columns mt-5 has-text-left is-vcentered p-2">
      <div class="column">
        <img class="rounded" loading="lazy" src="~@/assets/landing/Carl.jpg"/>
      </div>
      <div class="column ">
        <h2 class="title is-2 has-text-left">Carl Dupont</h2>
        <h5 class="subtitle has-text-left">{{ $t('landing.team.carl_title') }}</h5>
        <p v-html="$t('landing.team.carl_description')"/>
      </div>
    </section>


    <section class="columns has-text-left is-vcentered mt-5 p-2">
      <div class="column">
        <h2 class="title is-2 has-text-left">Nicolas Bourquard</h2>
        <h5 class="subtitle has-text-left">{{ $t('landing.team.nicolas_title') }}</h5>
        <p v-html="$t('landing.team.nicolas_description')"/>
      </div>

      <div class="column">
        <img class="rounded" loading="lazy" src="~@/assets/landing/Nicolas.jpg"/>
      </div>
    </section>
  </div>
</template>

<script>

export default{
  name: 'Team',
  metaInfo(){
    return {
      link: this.$getAlternates('team'),
    };
  },
};
</script>

<style lang="scss" scoped>
.rounded{
  border-radius: 20px;
}

.team-title {
  width: 350px;
  margin: auto;
  background: linear-gradient(to bottom, #FFF 50%, $primary-lighter 50%);
}
</style>
